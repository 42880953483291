const homeCaption = function() {
  var obj = $('.owl-item.active.center img')
  $('.caption').html(
    '<a href="'+obj.attr('data-url')+'">' +
    '  <div class="title">'+obj.attr('data-title')+'</div>' +
    '  <div class="quip">'+obj.attr('data-quip')+'</div>' +
    '</a>'
  )
}

$(function() {
  $('#home .main-carousel').owlCarousel({
    center: true,
    items: 1,
    loop: true,
    margin: 0,
    autoWidth: false,
    dots: true,
    nav: true,
    navText: ['&#12296;','&#12297;'],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      1120: {
        items: 2,
        autoWidth: true
      }
    },
    onTranslated: homeCaption,
    onInitialized: function() {
      homeCaption()
      $('.caption').show()
    }
  })
})
