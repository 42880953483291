import '../scss/index.scss'

import './header'
import './carousel'
import './timezone'

import { clamp } from '@clampy-js/clampy'

$(function() {
  $('.landing-page .quip, .search-results .quip, .article-carousel .quip').each(function(i, el) {
    clamp(el, { clamp: 3 })
  })
})
