import ujs from '@rails/ujs'
ujs.start()

import 'jquery'
import '@fortawesome/fontawesome-free/css/all'

import 'application/css'
import 'pcog/application/js'
import 'pcog/accounts/js'
import 'pcog/articles/js'
import 'pcog/help/js'
import 'pcog/pages/js'
import 'pcog/resources/js'
