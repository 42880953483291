const captionCredit = function() {
  let obj = $('.owl-item.active img')
  $('.caption').html(obj.attr('data-caption'))
  $('.credit').html(obj.attr('data-credit'))
}

$(function() {
  let el = $('.slideshow .owl-carousel')
  el.owlCarousel({
    items: 1,
    loop: true,
    margin: 0,
    autoWidth: false,
    center: true,
    dots: true,
    nav: true,
    navText: ['&#12296;','&#12297;'],
    autoplay: el.attr('data-autoplay') != 'false',
    autoplayTimeout: parseInt(el.attr('data-timeout')) || 5000,
    autoplayHoverPause: true,
    onTranslated: captionCredit,
    onInitialized: function() {
      captionCredit()
    }
  })
})
