$(function() {
  $('.menu-button').click(function() {
    $('.nav').toggleClass('open')
  })

  $('ul.sub-level').each(function(ix, el) {
    var submenu = $(el)
    var id = submenu.attr('data-parent-id')
    var parent = $('li[data-root-id="'+id+'"]')

    parent.mouseenter(function() {
      var css = {
        top: parent.position().top + parent.height(),
        visibility: 'visible',
        opacity: 1
      }

      if (parent.offset().left + submenu.width() > $(document).width()) {
        css['left'] = ''
        css['right'] = 0
      } else {
        css['left'] = parent.position().left
        css['right'] = ''
      }

      parent.addClass('hover')
      submenu.css(css)
    })

    $('ul[data-parent-id="'+id+'"], li[data-root-id="'+id+'"]').mouseleave(function() {
      setTimeout(function() {
        if (!(submenu.is(':hover') || parent.is(':hover'))) {
          parent.removeClass('hover')
          submenu.css({
            visibility: 'hidden',
            opacity: 0
          })
        }
      }, 100)
    })
  })
})

$(document).on('click', '#philadelphian-alert .close', function() {
  $('#philadelphian-alert').hide()
  document.cookie = 'philadelphianBanner=false;path=/;domain=' + document.location.host + ';max-age=31536000;secure;samesite=strict'
})
