import 'owl.carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

$(function() {
  $('.article-carousel').owlCarousel({
    items: 1,
    loop: true,
    dots: false,
    margin: 30,
    autoWidth: false,
    nav: true,
    navText: ['&#12296;','&#12297;'],
    responsive: {
      1281: { items: 5 },
      769: { items: 3 },
      401: { items: 2 }
    }
  })
})
